<template>
    <h1 itemprop="headline">
        <span class="blog-title">{{ title }}</span>
    </h1>
</template>

<script>
export default {
    name: 'ArticleTitle',
    props: ['title']
}
</script>

<style scoped>


    @media (max-width: 1200px) {

        h1 {
            font-size: 35px;
        }
    }

    @media (max-width: 992px) {
        h1 {
            font-size: 30px;
            width: 100%;
        }
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 23px;
            width: 100%;
            margin-top: 12px;
            margin-bottom: 12px;
            font-weight: bold;
        }
    }

    @media (max-width: 450px) {
        h1 {
            font-size: 20px;
            margin-top: 35px;
            margin-bottom: 25px;
        }
    }

</style>