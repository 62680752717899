<template>
  <div>
    <router-link
      v-if="previouspost"
      class="avia-post-nav avia-post-prev with-image"
      :to="postlink(previouspost.slug)"
    >
      <span
        class="label iconfont"
        aria-hidden="true"
        data-av_icon=""
        data-av_iconfont="entypo-fontello"
        ><font-awesome-icon icon="chevron-left"
      /></span>
      <span class="entry-info-wrap">
        <span class="entry-info">
          <span class="entry-title">{{ previouspost.title }}</span>
          <span class="entry-image">
            <img
              v-if="previouspost.featured_images[0]"
              width="80"
              height="80"
              data-src="1"
              :src="previouspost.featured_images[0].path"
              alt="aaa"
            />
            <img
              v-else
              width="80"
              height="80"
              src="@/assets/images/blog-article/no-image.png"
            />
          </span>
        </span>
      </span>
    </router-link>

    <router-link
      v-if="nextpost"
      class="avia-post-nav avia-post-next with-image"
      :to="postlink(nextpost.slug)"
    >
      <span
        class="label iconfont"
        aria-hidden="true"
        data-av_icon=""
        data-av_iconfont="entypo-fontello"
        ><font-awesome-icon icon="chevron-right"
      /></span>
      <span class="entry-info-wrap">
        <span class="entry-info">
          <span class="entry-image">
            <img
              v-if="nextpost.featured_images[0]"
              width="80"
              height="80"
              data-src="2"
              :src="nextpost.featured_images[0].path"
              alt="bbb"
            />
            <img
              v-else
              width="80"
              height="80"
              src="@/assets/images/blog-article/no-image.png"
            />
          </span>
          <span class="entry-title">{{ nextpost.title }}</span>
        </span>
      </span>
    </router-link>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "PrevNextPost",
  props: ["nextpost", "previouspost", "image"],
  components: {
    FontAwesomeIcon,
  },
  methods: {
    postlink(postslug) {
      return postslug + ".html";
    },
  },

  // mounted() {
  //   console.log(this.nextpost);
  // }
};
</script>
