<template>
  <div itemprop="articleBody" class="content main-content">
    <p v-html="content"></p>
  </div>
</template>

<script>
import fitvids from "fitvids";
import runOwlCarousel from "@/_helpers/owl-run.js";

export default {
  name: "ArticleDescription",
  props: ["content"],
  methods: {
    fitvideos() {
      this.$nextTick(function () {
        var b = document.querySelectorAll("iframe");
        if (b) {
          b.forEach(function (element) {
            element.setAttribute("allowFullScreen", "1");
          });
        }
        fitvids();
      });
    },
  },
  mounted() {
    this.fitvideos();
    runOwlCarousel();
  },
  updated() {
    this.fitvideos();
  },
};
</script>

<style scoped>
.main-content {
  /* font-family: Montserrat; */
  font-weight: 300;
  padding-bottom: 30px;
  padding-top: 30px;
}

.main-content >>> strong {
  font-weight: bold;
}

.main-content >>> .carousel-section h3 {
  color: #000;
  font-size: 48px;
  font-family: Raleway Bold;
  padding: 80px 0;
  width: 70%;
  margin: 0 auto;
}

.main-content >>> .carousel-section {
  padding: 20px 0;
  clear: both;
}

.main-content >>> .carousel-section .descrip > p {
  object-fit: contain;
  font-family: Montserrat Light;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
}

.main-content >>> .carousel-section .header .title {
  object-fit: contain;
  font-family: Raleway SemiBold;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #000000;
}

.main-content >>> .advantage-section-title {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.main-content >>> .advantage-section-subtitle {
  text-align: center;
  margin-bottom: 40px;
}

.main-content >>> .advantage-section-subtitle p {
  line-height: 0.8;
  font-size: 14px;
  margin: 0 0 10px;
}

.main-content >>> .advantage-icon {
  margin-bottom: 10%;
}

.main-content >>> .advantage-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}

.main-content >>> .advantage-desc {
  margin-bottom: 2%;
  min-height: 250px;
  /* font-family: Montserrat; */
  font-weight: 300;
  font-size: 14px;
  color: #111;
}

@media screen and (max-width: 991px) {
  .main-content >>> .tablet {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .main-content >>> .carousel-section .slider-wrapper {
    width: 100vw;
  }
  .arrows .cell {
    padding-top: 35px;
    padding-bottom: 20px;
  }

  .main-content >>> .align-carousel-right .grid-container .grid-x .cell.right-col {
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  .main-content >>> h3 {
    width: 100%;
    line-height: 24px;
    padding: 10px 0;
    font-size: 24px;
  }

  .main-content >>> .carousel-section .header .title {
    padding-bottom: 10px;
    padding-top: 0;
    font-size: 20px;
  }

  .main-content >>> .advantage-section-title {
    font-size: 30px;
  }
}

.carousel-section {
  padding: 20px 0;
}

.carousel-section .header .title {
  object-fit: contain;
  font-family: Raleway SemiBold;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #000000;
}

.carousel-section .descrip > p {
  object-fit: contain;
  font-family: Montserrat Light;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
}

.carousel-section .descrip > p > a {
  color: #000000;
}

.carousel-section .descrip > p > a {
  color: #000000;
}

@media screen and (min-width: 80em) {
  .carousel-section .slider-wrapper {
    /*padding-left:180px*/
  }
}
@media screen and (min-width: 90em) {
  .carousel-section .slider-wrapper {
    /*padding-left:160px*/
  }
}
.carousel-section .slide {
  width: 34vw;
  /* padding-bottom:30px; */
  position: relative;
}
@media screen and (min-width: 64em) {
  .carousel-section .slide {
    width: 30vw;
  }
}
@media screen and (min-width: 80em) {
  .carousel-section .slide {
    width: 35vw;
  }
}

@media screen and (min-width: 992px) {
  .carousel-section .slider-wrapper {
    width: 100vw;
  }
}
</style>
