<template>
  <div class="wrp-blog-title">
    <div class="date-article">
      <span
        class="blog_author"
        itemscope
        itemprop="author"
        itemtype="http://schema.org/Person"
        >Publicat de:
        <span itemprop="name">{{ author.first_name + " " + author.last_name }}</span
        >,
      </span>

      <span class="blog_add_date"
        >{{ dateformat(published) }}
        <!-- schema.org for date modify -->
      </span>

      <div class="wrp-share-buttons">
        Distribuie pe:
        <a
          target="_blank"
          OnClick="window.open(this.href,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'); return false;"
          :href="shareFBLink"
        >
          <img src="@/assets/images/blog-article/facebook.png" />
        </a>

        <a
          target="_blank"
          onclick="window.open(this.href, 'partage', 'height=400, width=500, top=300, left=300, toolbar=no, menubar=yes, location=no, resizable=yes, scrollbars=no, status=no'); return false;"
          :href="shareLKLink"
          title="Share on Linkedin"
          class="coco-linkedin"
          rel="nofollow"
        >
          <img src="@/assets/images/blog-article/linkedin.png" />
        </a>

        <a
          onclick="window.open(this.href, 'partage', 'height=400, width=500, top=300, left=300, toolbar=no, menubar=yes, location=no, resizable=yes, scrollbars=no, status=no'); return false;"
          :href="sharePLink"
          title="Share on Pinterest"
          rel="nofollow"
          data-pin-custom="true"
        >
          <img src="@/assets/images/blog-article/pinterest.png" />
        </a>

        <a data-pin-do="buttonPin" href="#" data-pin-custom="true"> </a>
      </div>

      <meta itemprop="datePublished" :content="dateformat(published)" />
      <meta itemprop="dateModified" :content="dateformat(updated)" />
    </div>
  </div>
</template>

<script>
import { blogMixin } from "@/mixins/blogMixin";

export default {
  name: "SocialShare",
  props: ["published", "updated", "author"],
  mixins: [blogMixin],
  computed: {
    shareFBLink() {
      return "http://www.facebook.com/share.php?u=" + this.fullUrl;
    },

    shareLKLink() {
      return "https://www.linkedin.com/sharing/share-offsite/?url=" + this.fullUrl;
    },

    sharePLink() {
      return "https://pinterest.com/pin/create/button/?url=" + this.fullUrl;
    },

    fullUrl() {
      return window.location.origin + this.$router.currentRoute.value.href;
    },
  },

  mounted() {
    // console.log(this.author);
  },
};
</script>
