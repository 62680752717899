<template>
  <div v-if="post.user" class="body" id="top">
    <div itemprop="haspart" itemscope itemtype="http://schema.org/Blog" id="post_page">
      <page-header :item="header_image" />
      <prev-next-post :nextpost="post.next_post" :previouspost="post.previous_post" />
      <div class="custom-site-content">
        <div class="container custom-container">
          <article
            itemprop="blogPost"
            itemscope
            itemtype="https://schema.org/BlogPosting"
          >
            <link itemprop="mainEntityOfPage" :href="$route.fullPath" />

            <!-- acest cod este stric pentru schema org, el este pe display none, nu este afisat (render); era nevoie de inca doua elemente(image si publisher ca sa nu mai fie eroare in instrumentul de testare google) -->
            <div
              itemprop="image"
              itemscope
              itemtype="https://schema.org/ImageObject"
              class="article_image"
            >
              <a :href="post.featured_images[0]?.path" itemprop="url">
                <img itemprop="contentURL" :src="post.featured_images[0]?.path"
              /></a>
              <meta itemprop="width" content="600" />
              <meta itemprop="height" content="222" />
            </div>
            <div
              itemprop="publisher"
              itemscope
              itemtype="https://schema.org/Organization"
              class="article_image"
            >
              <span itemprop="name"> Pe Foc </span>
              <div itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
                <meta itemprop="url" content="@/assets/images/logo.png" />
              </div>
            </div>
            <!-- sfarsit cod pt strict schema org -->

            <article-title :title="post.title" />
            <social-share
              :published="post.published_at"
              :author="post.user"
              :updated="post.updated_at"
            />
            <article-description :content="post.content" />
            <article-info :published="post.published_at" />
          </article>
        </div>
      </div>
      <div class="container-fluid position-relative">
        <div class="container">
          <section id="video_feed" class="inside-blog">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <!-- <article-carousel /> -->
                <video-feed></video-feed>
              </div>
            </div>
          </section>
        </div>
        <div class="bg-red one-section no-mobile"></div>
      </div>
      <more-articles />
    </div>
  </div>
</template>

<script>
import PrevNextPost from "@/components/blog_article/PrevNextPost.vue";
import SocialShare from "@/components/blog_article/SocialShare.vue";
import ArticleTitle from "@/components/blog_article/ArticleTitle.vue";
import ArticleDescription from "@/components/blog_article/ArticleDescription.vue";
import ArticleInfo from "@/components/blog_article/ArticleInfo.vue";
import MoreArticles from "@/components/blog_article/MoreArticles.vue";
// import ArticleCarousel from "@/components/blog_article/ArticleCarousel.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import VideoFeed from "@/components/blocks/video/VideoFeed.vue";

export default {
  name: "BlogArticle",
  components: {
    "social-share": SocialShare,
    "article-title": ArticleTitle,
    "article-description": ArticleDescription,
    "article-info": ArticleInfo,
    "prev-next-post": PrevNextPost,
    "more-articles": MoreArticles,
    // "article-carousel": ArticleCarousel,
    PageHeader,
    VideoFeed,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      isLoad: false,
    };
  },

  computed: {
    header_image() {
      let image = require("@/assets/images/blog-header-image.jpeg");
      // console.log(this.post);
      if (this.post && this.isLoad) {
        if (this.post.header_image) {
          image = this.post.header_image.path;
        } else if (this.post.featured_images.length) {
          image = this.post.featured_images[0].path;
        }
      }

      return {
        path: image,
      };
    },
    post() {
      // console.log(this.$store.getters['blog/post']);
      return this.$store.getters["blog/post"];
    },
  },

  methods: {
    fetchPost(slug) {
      this.$store.dispatch("blog/getPost", slug).then(() => {
        this.isLoad = true;
      });
    },
    fetchPosts() {
      this.$store.dispatch("blog/getPosts");
    },
  },

  async created() {
    this.fetchPost(this.$route.params.slug);
    await this.fetchPosts();
    if (this.$store.getters["vlog/bestVideos"].length === 0) {
      this.$store.dispatch("vlog/getBestVideos");
    }
    // console.log(this.post.featured_images);
  },

  beforeRouteUpdate(to, from, next) {
    this.fetchPost(to.params.slug);
    next();
  },
};
</script>

<style>
.article_image {
  display: none;
}
@import "../../assets/css/video-feeds.css";
@import "../../assets/css/blog.css";
@import "../../assets/css/blogPosts.css";
</style>
