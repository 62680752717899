<template>
  <p class="info">
    Posted in
    <a href="http://local.v3.pefoc.ro/blog-seminee/categorie/produse"
      >Produse</a
    >
    {{ dateformat(published) }}
  </p>
</template>

<script>
import { blogMixin } from "@/mixins/blogMixin";

export default {
  name: "ArticleInfo",
  props: ['published'],
  mixins: [blogMixin], 
};
</script>