<template>
  <!-- Alte articole -->
  <div class="container alte-articole pt-3">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 title">
        <h2 class="responsive-text mt-3">Articole recomandate</h2>
      </div>
    </div>

    <div class="row" id="content">

      

      <article v-for="post in posts" 
        :key="post.id" 
        :id="'article_'+ post.id"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12 block-item blog_noutati"
         itemprop="blogPosts" itemscope itemtype="https://schema.org/BlogPosting"
      >
        <div class="">
          <div class="block-img-wrp">
  
            <img v-if="post.featured_images[0]"
                itemprop="image"
                :src="post.featured_images[0].path"
                alt="aaa"
              />
            
            <img v-else
              itemprop="image"
              src="@/assets/images/blog-article/no-image.png"
              alt=""
            />
          </div>
          <div class="art-details">
            <div class="details">
              <span></span>
              <span>{{ dateformat(post.published_at) }}</span>
            </div>
            <div class="title">
              <h6>
                <router-link :to="postlink(post.slug)">{{
                  post.title
                }}</router-link>
              </h6>
            </div>
            <div class="blog-read-more">
              <router-link :to="postlink(post.slug)">CITESTE</router-link>
            </div>
          </div>
        </div>
      </article>

    </div>
    <div class="more-btn--wrapper">
      <router-link
        class="more-articles arrow--wrapper position-relative"
        to="/blog-seminee"
      >
        <span class="more-video--desc">Mai multe articole</span>
        <span class="arrow--right"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { blogMixin } from "@/mixins/blogMixin";

export default {
  mixins: [blogMixin], 
  computed: {
    posts() {
      const posts = this.$store.getters['blog/posts'];

      const postsWithoutCurrent = posts.data
        .filter(post => post.id !== this.post.id)
        .slice(0,4);

      // const postsWithoutCurrent = Object.keys(posts.data)
      //   .map(key => posts[key])
      //   .filter(post => post.id !== this.post.id)
      //   .slice(0,4);

      return postsWithoutCurrent;

    },

    post() {
      return this.$store.getters['blog/post'];
    }
  },

}
</script>